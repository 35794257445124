/* eslint-disable no-shadow */
import Vue from 'vue';
import payload from '@/utils/jwtPayload';
import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
} from '@/store/actions/user';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { tokenProvider } from '@/store/modules/auth';

/**
 * @description Тип аутентификации администратора.
 * @type {string}
 */
export const ADMIN_AUTH = 'admin';

/**
 * @description Тип аутентификации контент-менеджера.
 * @type {string}
 */
export const CONTENT_MANAGER_AUTH = 'content-manager';

const state = {
  available_sections: [],
  profile: {},
  role: null,
  status: '',
};

const getters = {
  authOf: (state) => {
    if (state.role === ADMIN_AUTH || state.role === CONTENT_MANAGER_AUTH) {
      return ADMIN_AUTH;
    }
    return null;
  },
  getProfile: (state) => state.profile,
  getRole: (state) => state.role,
  isProfileLoaded: (state) => !!state.profile.id,
};

const actions = {
  [USER_REQUEST]: ({ commit }) => {
    commit(USER_REQUEST);
    return Vue
      .axios
      .get('/api/profile')
      .then(async ({ data }) => {
        const profile = { ...data };
        profile.profile.initials = [data.profile.name, data.profile.surname]
          .reduce((initials, part) => initials + part.charAt(0), '');
        commit(USER_SUCCESS, data.profile);
        return data;
      }, (error) => {
        commit(USER_ERROR);
        throw error;
      });
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success';
    Vue.set(state, 'profile', resp);
    const token = tokenProvider.getToken();
    Vue.set(state, 'role', payload(token).property('role'));
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
  },
};

export default () => ({
  state,
  getters,
  actions,
  mutations,
});
