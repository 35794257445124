import Vue from 'vue';
import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
} from '@/store/actions/auth';

export const tokenProvider = {
  clearToken() {
    global.localStorage.removeItem('user-token');
  },
  getToken() {
    return global.localStorage.getItem('user-token') || '';
  },
  setToken(value) {
    global.localStorage.setItem('user-token', value);
  },
};

const initialState = {
  token: tokenProvider.getToken(),
  status: '',
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: (state) => state.token !== '',
  token: (state) => state.token,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST);
    Vue
      .axios
      .post('/api/auth/login', user)
      .then((response) => {
        tokenProvider.setToken(response.data.token);
        commit(AUTH_SUCCESS, response.data.token);
        resolve(response.data);
      })
      .catch((err) => {
        commit(AUTH_ERROR, err);
        tokenProvider.clearToken();
        reject(err);
      });
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    commit(AUTH_LOGOUT);
    tokenProvider.clearToken();
    resolve();
  }),
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = '';
  },
};

export default () => ({
  state: initialState,
  getters,
  actions,
  mutations,
});
