import { Either, Maybe } from 'monet';

/**
 * @description Шлюз планов лечения.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function TreatmentPlanGateway(axios) {
  /**
   * @description Создать шаблон плана лечения.
   * @param {any} request Данные для создания.
   * @returns {Promise<Either>}
   */
  this.createTemplate = (request) => axios
    .post('/api/admin/treatment-plan-templates', request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Отредактировать шаблон.
   * @param {number} templateId Идентификатор шаблона.
   * @param {any}    request    Запрос.
   * @returns {Promise<Either>}
   */
  this.editTemplate = (templateId, request) => axios
    .put(`/api/admin/treatment-plan-templates/${templateId}`, request)
    .then(
      (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Получить доступные статусы.
   * @return {Promise<any[]>}
   */
  this.getAllStatuses = () => Promise.resolve(
    [
      {
        text: 'Черновик',
        value: 'DRAFT',
      },
      {
        text: 'На согласовании',
        value: 'TO_BE_AGREED',
      },
      {
        text: 'Согласован',
        value: 'ACCEPTED_FOR_WORK',
      },
      {
        text: 'Отклонен пациентом',
        value: 'CANCELED_BY_PATIENT',
      },
      {
        text: 'В работе',
        value: 'IN_WORK',
      },
      {
        text: 'Выполнен',
        value: 'COMPLETED',
      },
    ],
  );

  /**
   * @description Получить доступные шаблоны.
   * @returns {Promise<Maybe<any[]>>}
   */
  this.getAllTemplates = () => axios
    .get('/api/admin/treatment-plan-templates')
    .then((response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить единицы измерения услуги по умолчанию.
   * @return {Promise<any[]>}
   */
  this.getDefaultServiceUnits = async () => Promise.resolve(
    [
      {
        value: 'за 1 зуб',
        title: 'за 1 зуб',
      },
      {
        value: 'за 1 челюсть',
        title: 'за 1 челюсть',
      },
      {
        value: 'за обе челюсти',
        title: 'за обе челюсти',
      },
      {
        value: '1 шт.',
        title: '1 шт.',
      },
    ],
  );

  /**
   * @description Удалить шаблон.
   * @param {number} templateId Идентификатор шаблона.
   * @returns {Promise<Either>}
   */
  this.removeTemplate = (templateId) => axios
    .delete(`/api/admin/treatment-plan-templates/${templateId}`)
    .then(
      (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
}
