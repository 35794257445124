import axiosStatic from 'axios';

/**
 * @description Создать новый экземпляр клиента Axios.
 * @param {string}             baseURL            Базовый ресурс запросов.
 * @param {function(): string} resolveToken       Получить токен аутентификации.
 * @param {string}             defaultContentType Тип содержимого запроса по умолчанию.
 * @returns {import('axios').AxiosInstance}
 */
export function createAxios(
  baseURL,
  resolveToken,
  defaultContentType = 'application/json',
) {
  const headers = { 'Content-Type': defaultContentType };
  const instance = axiosStatic.create({ baseURL, headers });
  instance.interceptors.request.use((config) => {
    const token = resolveToken();
    if (token !== '') {
      Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    }
    if ((config.headers['Content-Type'] || '') === '') {
      Object.assign(config.headers, { 'Content-Type': defaultContentType });
    }
    return config;
  });
  return instance;
}

/**
 * @description Создать новый экземпляр клиента Axios с установкой перехватчиков на ошибки.
 * @param {string}             baseURL
 * @param {function(): void}   onUnauthorized
 * @param {function(): void}   onServiceError
 * @param {function(): string} resolveToken
 * @returns {import('axios').AxiosInstance}
 */
export function createAxiosWithErrorHandlers(
  baseURL,
  onUnauthorized,
  onServiceError,
  resolveToken,
) {
  const instance = createAxios(baseURL, resolveToken);
  instance.interceptors.response.use(undefined, (error) => {
    const { response: { status } } = error;
    const { config: { __isRetryRequest: isRetry } } = error;
    if (status === 401 && isRetry) {
      onUnauthorized();
    }
    if (status === 500) {
      onServiceError();
    }
    throw error;
  });
  return instance;
}
