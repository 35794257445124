/* eslint-disable no-shadow */
import {
  SNACKBAR, SNACKBAR_TEXT, SNACKBAR_COLOR, SNACKBAR_TIMEOUT, SNACKBAR_ALIGN,
} from '../actions/snackbar';

const state = {
  snackbar: false,
  text: '',
  color: 'green darken-2',
  timeout: 3000,
  align: {
    bottom: true,
    right: true,
  },
};

const getters = {
  snackbar: (state) => state.snackbar,
  text: (state) => state.text,
  color: (state) => state.color,
  timeout: (state) => state.timeout,
};

const actions = {
};

const mutations = {
  [SNACKBAR]: (state, value) => {
    state.snackbar = value;
  },
  [SNACKBAR_TEXT]: (state, value) => {
    state.text = value;
  },
  [SNACKBAR_COLOR]: (state, value) => {
    state.color = value;
  },
  [SNACKBAR_TIMEOUT]: (state, value) => {
    state.timeout = value;
  },
  [SNACKBAR_ALIGN]: (state, value) => {
    state.align = value;
  },
};

export default () => ({
  state,
  getters,
  actions,
  mutations,
});
