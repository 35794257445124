import { Maybe, Some, None } from 'monet';

const jsonParse = (json) => {
  try {
    return Some(JSON.parse(json));
  } catch {
    return None();
  }
};

/**
 * @description JWT парсер полезной нагрузки.
 * @param {string} token
 */
const jwt = (token) => {
  const payload = Maybe.fromFalsy(token)
    .chain((value) => Maybe.fromFalsy(value.split('.')[1]))
    .chain((base64) => Maybe.fromFalsy(window.atob(base64)))
    .chain(jsonParse);
  return {
    /**
     * @description Получить значение по ключу.
     * @param {string} key Ключ.
     * @returns {any}
     */
    property: (key) => payload.fold(null)((value) => value[key] || null),
    /**
     * @description Получить полезную нагрузку.
     * @returns {Maybe}
     */
    getPayload: () => payload,
  };
};

export default jwt;
