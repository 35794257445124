import { Either, List } from 'monet';

/**
 * @description Шлюз ИЭМК ЕГИСЗ.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function EgiszIemkGateway(axios) {
  /**
   * @description Подключить клинику.
   * @param {string} clinicId Идентификатор клиники.
   * @param {string} lpuId    Идентификатор ЛПУ.
   * @returns {Promise<Either>}
   */
  this.enableClinic = (clinicId, lpuId) => axios
    .put(`/api/admin/iemk-clinics/${clinicId}`, { lpu_id: lpuId })
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Получить страницу подключенных клиник.
   * @returns {Promise<List<any>>}
   */
  this.getEnabledClinics = () => axios
    .get('/api/admin/iemk-clinics')
    .then((response) => List.from(response.data.data));

  /**
   * @description Получить совпавшие клиники доступные для подключения.
   * @param {string} query Поисковый запрос.
   * @returns {Promise<List<any>>}
   */
  this.searchCandidates = (query) => axios
    .get('/api/admin/iemk-candidate-clinics', { params: { query } })
    .then((response) => List.from(response.data.data));
}
