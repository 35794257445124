import {
  SNACKBAR,
  SNACKBAR_ALIGN,
  SNACKBAR_COLOR,
  SNACKBAR_TEXT,
  SNACKBAR_TIMEOUT,
} from '@/store/actions/snackbar';

/**
 * @description Выровнять по нижнему правому караю.
 * @type {string}
 */
const ALIGN_BOTTOM_RIGHT = 'bottom-right';

/**
 * @description Выровнять по центру.
 * @type {string}
 */
const ALIGN_CENTERED = 'centered';

/**
 * @description Базовое время показа.
 * @type {number}
 */
export const BASE_TIMEOUT = 3000;

/**
 * @description Длительное время показа.
 * @type {number}
 */
export const LONG_TIMEOUT = BASE_TIMEOUT * 3;

const ALIGN = {
  [ALIGN_BOTTOM_RIGHT]: {
    bottom: true,
    centered: false,
    right: true,
  },
  [ALIGN_CENTERED]: {
    bottom: false,
    centered: true,
    right: false,
  },
};

export default {
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar.snackbar;
      },
      set(value) {
        this.$store.commit(SNACKBAR, value);
      },
    },
    snackbarAlign: {
      get() {
        return this.$store.state.snackbar.align;
      },
      set(value) {
        this.$store.commit(SNACKBAR_ALIGN, value);
      },
    },
    snackbarText: {
      get() {
        return this.$store.state.snackbar.text;
      },
      set(value) {
        this.$store.commit(SNACKBAR_TEXT, value);
      },
    },
    snackbarColor: {
      get() {
        return this.$store.state.snackbar.color;
      },
      set(value) {
        this.$store.commit(SNACKBAR_COLOR, value);
      },
    },
    snackbarTimeout: {
      get() {
        return this.$store.state.snackbar.timeout;
      },
      set(value) {
        this.$store.commit(SNACKBAR_TIMEOUT, value);
      },
    },
  },
  methods: {
    showSnackbar(message, color, align = ALIGN_BOTTOM_RIGHT, timeout = BASE_TIMEOUT) {
      this.snackbarTimeout = 0;
      this.$nextTick(() => {
        this.snackbarAlign = ALIGN[align] || ALIGN[ALIGN_BOTTOM_RIGHT];
        this.snackbarColor = color;
        this.snackbarText = message;
        this.snackbarTimeout = timeout;
        this.snackbar = true;
      });
    },
  },
};
