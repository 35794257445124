/**
 * @description Тип события проверки обновления.
 * @type {string}
 */
const CHECK_UPDATE_EVENT_TYPE = 'updater:checkupdate';

/**
 * @description Создать обработчик перезапуска через Service Worker.
 * @param {ServiceWorkerRegistration} registration Регистрация SW.
 * @returns {function(Location): void}
 */
const createServiceWorkerRefreshAdapter = (registration) => (location) => {
  const { navigator: { serviceWorker } } = global;
  const { waiting } = registration;
  if (waiting) {
    const onReady = () => {
      location.reload();
    };
    serviceWorker.addEventListener('controllerchange', onReady, { once: true });
    waiting.postMessage({ type: 'SKIP_WAITING' }); // trigger refresh
  }
};

/**
 * @description Установить обработчик по регистрации Service Worker.
 * @param {ServiceWorkerRegistration} registration Регистрация SW.
 * @returns {void}
 */
export const onServiceWorkerRegistration = (registration) => {
  const { document } = global;
  if (document) {
    const detail = { initRefresher: createServiceWorkerRefreshAdapter(registration) };
    const checkUpdateEvent = new CustomEvent(CHECK_UPDATE_EVENT_TYPE, { detail });
    document.dispatchEvent(checkUpdateEvent);
  }
};

/**
 * @description Плагин для обновлений.
 * @constructor
 * @type {VuePlugin}
 */
export default function Updater() {
  /**
   * @description Создать обработчик проверки обновления.
   * @param {Location} location
   * @returns {function(CustomEvent): void}
   */
  const createCheckUpdateEventHandler = (location) => (event) => {
    // confirm a refresh where when needed
    event.detail.initRefresher(location);
  };

  /**
   * @inheritDoc
   */
  this.install = () => {
    const { document, location } = global;
    if (document && location) {
      const checkUpdateHandler = createCheckUpdateEventHandler(location);
      document.addEventListener(CHECK_UPDATE_EVENT_TYPE, checkUpdateHandler, { once: true });
    }
  };
}
