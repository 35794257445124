import { Either } from 'monet';

/**
 * @description Запрос получения списка истории рассылок.
 * @param {number} page
 * @param {string} clinicId
 * @param {string} theme
 * @param {string} address
 * @param {string} fromFiredAt
 * @param {string} toFiredAt
 * @param {string} fromCreatedAt
 * @param {string} toCreatedAt
 * @param {string} channel
 * @param {string} selectedStatus
 * @param {string} sortType
 * @param {string} sortDirection
 * @constructor
 */
export function GetListMessagesRequest(
  page = 1,
  clinicId = '',
  theme = '',
  address = '',
  fromFiredAt = '',
  toFiredAt = '',
  fromCreatedAt = '',
  toCreatedAt = '',
  channel = '',
  selectedStatus = '',
  sortType = '',
  sortDirection = '',
) {
  this.page = page;
  this.clinicId = clinicId;
  this.theme = theme;
  this.address = address;
  this.fromFiredAt = fromFiredAt;
  this.toFiredAt = toFiredAt;
  this.fromCreatedAt = fromCreatedAt;
  this.toCreatedAt = toCreatedAt;
  this.channel = channel;
  this.selectedStatus = selectedStatus;
  this.sortType = sortType;
  this.sortDirection = sortDirection;

  this.toQuery = () => ({
    page: this.page,
    clinic_id: this.clinicId,
    theme: this.theme,
    address: this.address,
    from_fired_at: this.fromFiredAt,
    to_fired_at: this.toFiredAt,
    from_created_at: this.fromCreatedAt,
    to_created_at: this.toCreatedAt,
    channel: this.channel,
    selected_status: this.selectedStatus,
    sort_type: this.sortType,
    sort_direction: this.sortDirection,
  });
}

/**
 * @description Шлюз истории рассылок.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function MessageHistoryGateway(axios) {
  /**
   * @description Получить историю рассылок.
   * @param {GetListMessagesRequest} request Запрос.
   * @returns {Promise}
   */
  this.getList = (request) => axios
    .get('/api/admin/message-history', { params: request.toQuery() })
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
}
