import store, { tokenProvider } from '@/store';
import { USER_SUCCESS } from '@/store/actions/user';
import { ADMIN_AUTH } from '@/store/modules/user';
import jwt from '@/utils/jwtPayload';

function adminGuard(next) {
  if (store.getters.isProfileLoaded) { return; }
  const token = tokenProvider.getToken();
  if (!token) {
    next({ name: 'Auth' });
  }
  jwt(token).getPayload().forEach(({ sub, exp }) => {
    const currentTime = Math.floor(Date.now() / 1000);
    if (exp < currentTime) {
      next({ name: 'Auth' });
      return;
    }
    store.commit(USER_SUCCESS, { id: sub });
  });
}

async function guard(to, from, next) {
  if (!to.name) {
    next({ name: 'Auth' });
    return;
  }
  const authGroups = to.matched.flatMap(({ meta }) => ('authOf' in meta ? [meta.authOf] : []));
  if (authGroups.includes(ADMIN_AUTH)) {
    adminGuard(next);
  }
  const isAllowed = authGroups.every((group) => group === store.getters.authOf);
  if (isAllowed) {
    next();
  } else {
    next('/login');
  }
}

export default guard;
