/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { onServiceWorkerRegistration } from '@/plugins/updater';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    /**
     * @description Обработчик замены ранее установленного SW (перерегистрация).
     * @returns {void}
     * @link https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/updatefound_event
     */
    updatefound() {
      console.log('New content is downloading.');
    },
    /**
     * @description Обработчик изменения состояния ранее установленного SW.
     * @returns {void}
     * @link https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorker/statechange_event
     */
    updated(registration) {
      console.log('New content is available; please refresh.');
      onServiceWorkerRegistration(registration);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
