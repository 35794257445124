import '@/registerServiceWorker';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import Updater from '@/plugins/updater';
import App from '@/App.vue';
import store, { onServiceError } from '@/store';
import Router from '@/router';
import vuetify from '@/plugins/vuetify';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { VUE_APP_ROOT_API } from '@/transformEnv';
import { VueStoragePlugin } from '@/storage';
import { createAxiosWithErrorHandlers } from '@/plugins/axios';

const router = new Router();
const resolveToken = () => store.getters.token;
const storageAxios = createAxiosWithErrorHandlers(
  VUE_APP_ROOT_API,
  () => { store.dispatch(AUTH_LOGOUT).then(() => { router.push({ name: 'Auth' }); }); },
  () => { onServiceError(store); },
  resolveToken,
);

Vue.config.productionTip = false;

Vue.use(VueAxios, storageAxios);
Vue.use(new VueStoragePlugin(storageAxios));
Vue.use(VueRouter);
Vue.use(new Updater());

new Vue({
  render: (createElement) => createElement(App),
  router,
  store,
  vuetify,
}).$mount('#app');
