import { Fail, Success } from 'monet';

/**
 * @description Клиника.
 * @param {number} id Идентификатор.
 * @param {string} name Название.
 * @constructor
 */
function Clinic(id, name) {
  this.id = id;
  this.name = name;
}

/**
 * @description Уведомление.
 * @param {number} id        Идентификатор уведомления.
 * @param {string} title     Заголовок.
 * @param {string} body      Текст уведомления.
 * @param {string} date      Время отправки уведомления.
 * @param {?string} image    Изображение.
 * @param {?string} videoURL Видео.
 * @constructor
 */
export function Notification(id, title, body, date, image, videoURL) {
  this.id = id;
  this.date = date;
  this.title = title;
  this.body = body;
  this.image = image;
  this.videoURL = videoURL;
}

/**
 * @description Шлюз уведомлений администратора.
 * @param {Axios} axios
 */
export default function NotificationGateway(axios) {
  /**
   * @description Получить список клиник для показа.
   * @returns {Promise<Clinic[]>}
   */
  this.getClinicsForView = () => axios
    .get('/api/admin/suggestions/notification-creation/clinics')
    .then((response) => (response.data || []).map((item) => new Clinic(item.id, item.name)));

  /**
   * @description Получить список уведомлений для показа.
   * @param {number} page Номер страницы.
   * @returns {Promise<Notification[]>}
   */
  this.getListForView = async (page) => {
    const response = await axios.get('/api/admin/notifications', { params: { page } });
    const { data } = response.data;
    return {
      items: (data.items || [])
        .map((item) => new Notification(
          item.id,
          item.title,
          item.body,
          item.date,
          item.image,
          item.videoURL,
        )),
      pageCount: data.page_count,
      currentPage: data.current_page,
    };
  };

  /**
   * @description Получить уведомляемые события.
   * @returns {Promise<any[]>}
   */
  this.getNotifiableEvents = () => axios
    .get('/api/admin/notifiable-events')
    .then(async (response) => response.data.data);

  /**
   * @description Отправить запрос на создание уведомления.
   * @param {any} request Запрос.
   * @returns {Promise<Validation>}
   */
  this.sendCreateNotification = (request) => axios
    .post('/api/admin/notifications', request)
    .then(
      async (response) => {
        const { message } = response.data;
        return Success({
          message,
        });
      },
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          const causes = error.response.data.causes || [];
          const { title } = error.response.data;
          return Fail({
            causes,
            title,
          });
        }
        throw error;
      },
    );

  /**
   * @description Отправить запрос на удаление уведомления.
   * @param {number} notificationId Идентификатор уведомления.
   * @returns {Promise<Validation>}
   */
  this.sendDelete = (notificationId) => axios
    .delete(`/api/admin/notifications/${notificationId}`)
    .then(
      async (response) => Success({
        message: response.data.message,
        notificationId: response.data.notification_id,
      }),
      async (error) => {
        if (error.isAxiosError && error.response.status === 404) {
          const { title } = error.response.data;
          return Fail({ title });
        }
        throw error;
      },
    );

  /**
   * @description Получить количество событий по разделам.
   * @returns {Promise<any[]>}
   */
  this.getCountEventBySections = () => axios
    .get('/api/admin/sections/count-events')
    .then(async (response) => response.data.data);
}
