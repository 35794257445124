/**
 * @description Идентификатор состояния ошибки аутентификации.
 * @type {string}
 */
export const AUTH_ERROR = 'AUTH_ERROR';

/**
 * @description Идентификатор действия сброса текущей аутентификации.
 * @type {string}
 */
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

/**
 * @description Идентификатор действия прохождения аутентификации.
 * @type {string}
 */
export const AUTH_REQUEST = 'AUTH_REQUEST';

/**
 * @description Идентификатор состояния успешной аутентификации.
 * @type {string}
 */
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
