import VueRouter from 'vue-router';
import guard from '@/router/guard';
import routes from '@/views';

/**
 * @description Роутер.
 * @constructor
 * @augments VueRouter
 */
export default function Router() {
  VueRouter.call(this, { mode: 'history', routes });
  this.beforeEach(guard);
}

Router.prototype = Object.create(VueRouter.prototype);
