import { Either, Maybe } from 'monet';
import LeftResponse from '@/storage/LeftResponse';

/**
 * @description Шлюз заказа консультации.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function ConsultationOrderGateway(axios) {
  /**
   * @description Получить список предложенных идей.
   * @param {number} page Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.viewList = (page, match) => axios
    .get('/api/admin/consultation-orders', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Пометить предложенную идею прочитанной.
   * @param {number} id Идентификатор предложенной идеи.
   * @returns {Promise<Either>}
   */
  this.review = (id) => axios
    .put(`/api/admin/consultation-orders/${id}/review`)
    .then(
      async (response) => Either.right(response.data.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.left(error.response.data.data.title);
        }
        if (error.isAxiosError && error.response.status === 500) {
          const left = new LeftResponse([], 'Произошла ошибка на сервере');
          return Either.left(left);
        }
        throw error;
      },
    );
}
