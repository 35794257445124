import { Maybe } from 'monet';

/**
 * @description Шлюз классификатора.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function ClassifierGateway(axios) {
  /**
   * @description Найти стоматологические услуги.
   * @param {string} query Поисковый запрос.
   * @return {Promise<Maybe<{code: string, name: string}[]>>}
   */
  this.searchDentalServices = (query = '') => {
    const config = {};
    if (query !== '') {
      config.params.query = query;
    }
    return axios
      .get('/api/admin/dental-services', config)
      .then((response) => Maybe.fromEmpty(response.data.data));
  };

  /**
   * @description Найти болезни по классификатору МКБ-10.
   * @param {string} query    Поисковое вхождение.
   * @param {string} category Категория.
   * @returns {Promise<Maybe>}
   */
  this.searchIcd10Diseases = (query, category = '') => axios
    .get('/api/admin/classifiers/icd10/diseases', {
      params: {
        category,
        query,
      },
    })
    .then(async (response) => Maybe.fromEmpty(response.data.data));
}
