import { Either, Maybe } from 'monet';

/**
 * @description Шлюз протоколов приема.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function VisitDiaryGateway(axios) {
  /**
   * @description Создать шаблон.
   * @param {any} data Содержимое запроса.
   * @returns {Promise<Either>}
   */
  this.createTemplate = (data) => axios
    .post('/api/admin/visit-diary-templates', data)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Получить категории для шаблонов протокола приема.
   * @returns {Promise<any[]>}
   */
  this.getAllCategoriesForDiaryTemplate = () => Promise.resolve(
    [
      {
        text: 'Имплантация',
        value: 'implantation',
      },
      {
        text: 'Ортодонтия',
        value: 'orthodontics',
      },
      {
        text: 'Ортопедия',
        value: 'orthopedics',
      },
      {
        text: 'Хирургия',
        value: 'surgery',
      },
      {
        text: 'Терапия',
        value: 'therapy',
      },
    ],
  );

  /**
   * @description Получить шаблоны.
   * @returns {Promise<Maybe>}
   */
  this.getTemplates = () => axios
    .get('/api/admin/visit-diary-templates')
    .then(async (response) => Maybe.fromEmpty(response.data.data));

  /**
   * @description Удалить шаблон.
   * @param {number} templateId Идентификатор шаблона.
   * @returns {Promise<Either>}
   */
  this.removeTemplate = (templateId) => axios
    .delete(`/api/admin/visit-diary-templates/${templateId}`)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Обновить шаблон.
   * @param {number} templateId Идентификатор шаблона.
   * @param {any}    data       Данные шаблона.
   * @returns {Promise<Either>}
   */
  this.updateTemplate = (templateId, data) => axios
    .put(`/api/admin/visit-diary-templates/${templateId}`, data)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
}
