import Vue from 'vue';
import Vuex from 'vuex';
import {
  SNACKBAR,
  SNACKBAR_COLOR,
  SNACKBAR_TEXT,
  SNACKBAR_TIMEOUT,
} from '@/store/actions/snackbar';
import createAuthModule, { tokenProvider } from '@/store/modules/auth';
import createSnackbarModule from '@/store/modules/snackbar';
import createUserModule from '@/store/modules/user';

const strict = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export { tokenProvider };

export const onServiceError = (instance) => {
  instance.commit(SNACKBAR_TEXT, 'Сервис не доступен. Попробуйте позже.');
  instance.commit(SNACKBAR_COLOR, 'red');
  instance.commit(SNACKBAR_TIMEOUT, 3000);
  instance.commit(SNACKBAR, true);
};

export default new Vuex.Store({
  modules: {
    auth: createAuthModule(),
    snackbar: createSnackbarModule(),
    user: createUserModule(),
  },
  strict,
});
