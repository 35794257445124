import { Fail, Success } from 'monet';

/**
 * Группа обновлений
 *
 * @param date {string}
 * @param updates {array}
 * @constructor
 */
export function GroupUpdate(
  date,
  updates,
) {
  this.updates = updates;
  this.date = date;
}

/**
 * Конструктор Обновления
 *
 * @param id
 * @param title
 * @param description
 * @param linkAnchor
 * @param photoUrl
 * @param dateOfCreate
 * @constructor
 */
export function Update(
  id,
  title = '',
  description = '',
  linkAnchor = '',
  photoUrl = '',
  dateOfCreate = '',
) {
  this.id = id;
  this.title = title;
  this.description = description;
  this.linkAnchor = linkAnchor;
  this.photoUrl = photoUrl;
  this.dateOfCreate = dateOfCreate;
}
/**
 * @description Шлюз обновлений.
 * @param {Axios} axios
 */
export default function UpdateGateway(axios) {
  /**
   * @description Получить список обновлений.
   * @param page {Number}
   * @returns {Promise<Success|Fail>}
   */
  this.getListUpdates = async (page) => {
    try {
      const response = await axios.get(
        '/api/admin/updates',
        { params: { page } },
      );
      const groups = response.data.data.groups.map((rawGroupUpdates) => new GroupUpdate(
        rawGroupUpdates.date,
        rawGroupUpdates.updates.map((rawUpdate) => new Update(
          rawUpdate.id,
          rawUpdate.title,
          rawUpdate.description,
          rawUpdate.link_anchor,
          rawUpdate.photo_url,
          rawUpdate.date_of_create,
        )),
      ));
      const currentPage = response.data.data.page_current;
      const countPage = response.data.data.page_count;
      return { groups, currentPage, countPage };
    } catch (err) {
      return Fail(err);
    }
  };
  /**
   * Удалить обновление
   *
   * @param updateId
   * @returns {Promise<Success|Fail>}
   */
  this.removeUpdate = async (updateId) => {
    try {
      const response = await axios.delete(`/api/admin/updates/${updateId}`);
      return Success(response.data);
    } catch (err) {
      return Fail(err.response.data.title);
    }
  };
  /**
   * Удалить обновление
   *
   * @param dateOfCreate {string}
   * @returns {Promise<Success|Fail>}
   */
  this.removeGroupUpdates = async (dateOfCreate) => {
    try {
      const response = await axios.delete(`/api/admin/updates/delete-group/${dateOfCreate}`);
      return Success(response.data);
    } catch (err) {
      return Fail(err.response.data.title);
    }
  };
  /**
   * Создать обновление
   *
   * @param data {FormData}
   * @returns {Promise<Success|Fail>}
   */
  this.createUpdate = async (data) => {
    try {
      const response = await axios.post('/api/admin/updates', data);
      return Success(response.data);
    } catch (err) {
      return Fail(err.response.data);
    }
  };
  /**
   * Изменить обновление
   *
   * @param id {Number}
   * @param data {FormData}
   * @returns {Promise<Success|Fail>}
   */
  this.editUpdate = async (id, data) => {
    try {
      const response = await axios.post(`/api/admin/updates/${id}/edit`, data);
      return Success(response.data);
    } catch (err) {
      return Fail(err.response.data);
    }
  };
}
