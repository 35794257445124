/**
 * @description Неудачный ответ.
 * @param {{details:string[], source:string}[]} causes Причины.
 * @param {string}                              title  Заголовок.
 * @constructor
 */
function LeftResponse(causes, title) {
  /**
   * @description Собрать причины.
   * @returns {string}
   */
  const makeCauses = () => {
    const failurePairs = (causes || [])
      .map((cause) => [cause.source, cause.details]);
    return Object.fromEntries(failurePairs) || {};
  };
  /**
   * @description Получить все причины.
   * @returns {string}
   */
  this.allCauses = () => makeCauses(causes);
  /**
   * @description Получить заголовок.
   * @returns {string}
   */
  this.getTitle = () => title;
  /**
   * @descriptions Имеет причины.
   * @returns {boolean}
   */
  this.hasCauses = () => (causes || []).length !== 0;

  /**
   * @description Имеет источник.
   * @param {string} source Источник.
   * @returns {boolean}
   */
  this.has = (source) => !!causes.find((cause) => cause.source === source);
}

export default LeftResponse;
