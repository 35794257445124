import { Maybe } from 'monet';

/**
 * @description Шлюз интегрированных клиник.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function IntegratedClinicsGateway(axios) {
  /**
   * @description Получить интегрированные клиники.
   * @param {number} page     Номер страницы.
   * @param {number} perPage  Количество элементов на страницу.
   * @param {string} match    Поисковое совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getClinics = (page = 1, perPage = 20, match = '') => axios
    .get(
      '/api/admin/integrated-clinics',
      { params: { match, page, perPage } },
    )
    .then((response) => Maybe.fromEmpty(response.data));
}
