import { Either, Maybe } from 'monet';

/**
 * @description Шлюз АТОЛ Онлайн.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function OnlineCheckGateway(axios) {
  /**
   * @description Получить клиники-кандидаты.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match) => axios
    .get('/api/admin/online-check-candidate-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить клиники с аккаунтами.
   * @param {number} page      Номер страницы.
   * @param {string} match     Совпадение.
   * @param {string} type      Тип сортировки.
   * @param {string} direction Направление сортировки.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithAccount = (page, match = '', type = '', direction = '') => axios
    .get(
      '/api/admin/online-check/accounts',
      {
        params: {
          match,
          page,
          sort_type: type,
          sort_direction: direction,
        },
      },
    )
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Подключить тестовый аккаунт.
   * @param {number} clinicId
   * @returns {Promise<Either>}
   */
  this.connectTestAccount = (clinicId) => axios
    .post(`/api/admin/clinics/${clinicId}/cashbox/test-account`)
    .then(
      async (response) => Either.Right(response.data.title),
      async (error) => {
        if (error.isAxiosError && [400, 404].includes(error.response.status)) {
          return Either.Left(error.response.data.title);
        }
        throw error;
      },
    );

  /**
   * @description Создать аккаунт.
   * @param {number} id
   * @param {string} email
   * @param {string} ffdVersion
   * @param {string} startsAt
   * @param {string} expiresAt
   * @returns {Promise<Either>}
   */
  this.createAccount = (id, email, ffdVersion, startsAt, expiresAt) => axios
    .post(
      `/api/admin/clinics/${id}/online-check/accounts`,
      {
        email,
        ffd_version: ffdVersion,
        starts_at: startsAt,
        expires_at: expiresAt,
      },
    )
    .then(
      async (response) => Either.Right(response.data.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Создать аккаунт.
   * @param {number} id
   * @param {string} email
   * @param {string} ffdVersion
   * @param {string} startsAt
   * @param {string} expiresAt
   * @returns {Promise<Either>}
   */
  this.recreateAccount = (
    id,
    email,
    ffdVersion,
    startsAt,
    expiresAt,
  ) => axios
    .put(
      `/api/admin/clinics/${id}/online-check/recreate-account`,
      {
        email,
        ffd_version: ffdVersion,
        starts_at: startsAt,
        expires_at: expiresAt,
      },
    )
    .then(
      async (response) => Either.Right(response.data.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Отключить кассу клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.disableCashbox = (clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/online-check/disable`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Включить кассу клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.enableCashbox = (clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/online-check/enable`)
    .then(async (response) => Maybe.fromEmpty(response.data));
}
